import React, { useState, useEffect } from 'react'
import { graphql,  Link, navigate, useStaticQuery } from 'gatsby';
import Layout from "../../../components/UkResidents/HCP/layout";
import SEO from "../../../components/seo"
import {isNullUndefined, isEmptyArray} from "../../../util/index"
import searchStyles from "../../../components/search/search.module.css"
import { useSelector, useDispatch } from "react-redux"
import {ukHcpSearchTermOnchange} from "../../../actions/ukResidents/hcp/util/search_onchange"
import ScrollAnimation from 'react-animate-on-scroll';
import ShowMore from 'react-show-more';
import ReactPaginate from 'react-paginate';
import {getSearchedPagePath, graphqlSearch} from "../../../controllers/ukResidents/hcp/search"
import queryString from 'query-string';

const ukHcpSearchQuery = graphql`
query UkHcpSearchQuery {
  allSitePage {
    edges {
      node {
        path
      }
    }
  }
  allContentfulMenuItem {
    edges {
      node {
        slug
        position
        title
        childContentfulMenuItemDescriptionRichTextNode {
          childContentfulRichText {
            html
          }
        }
        spaceId
        default_template {
          slug
          title
          childContentfulDefaultTemplateBodyTextRichTextNode {
            childContentfulRichText {
              html
            }
          }
        }
        cards_only_template {
          slug
          title
        }
        tabs_for_files_template {
          slug
          title
          childContentfulTabsForFilesTemplateBodyTextRichTextNode{
            childContentfulRichText{
              html
            }
          }
        }
        country_information_template {
          slug
          title
          childContentfulCountryInformationTemplateBodyTextRichTextNode {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
  }
  allContentfulDefaultTemplate {
    edges {
      node {
        title
        slug
        spaceId
        childContentfulDefaultTemplateBodyTextRichTextNode {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
  allContentfulCardsOnlyTemplate {
    edges {
      node {
        title
        slug
        spaceId
        cardLayout {
          description
          title
        }
      }
    }
  }
  allContentfulTabsForFilesTemplate {
    edges {
      node {
        title
        slug
        spaceId
        childContentfulTabsForFilesTemplateBodyTextRichTextNode {
          childContentfulRichText{
            html
          }
        }
      }
    }
  }
  allContentfulCountryInformationTemplate {
    edges {
      node {
        title
        slug
        spaceId
        childContentfulCountryInformationTemplateBodyTextRichTextNode {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
  allContentfulVideoPdfTemplate {
    edges {
      node {
        title
        slug
        spaceId
        childContentfulVideoPdfTemplateBodyTextRichTextNode {
          childContentfulRichText {
            html
          }
        }
        childContentfulVideoPdfTemplateVideoSectionTopTextRichTextNode {
          childContentfulRichText {
            html
          }
        }
        childContentfulVideoPdfTemplateVideoSectionSideTextRichTextNode {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
  allContentfulNewsItemTemplate {
    edges {
      node {
      author
      datePublished
      childContentfulNewsItemTemplateDescriptionRichTextNode {
        childContentfulRichText {
          html
        }
      }
      slug
      spaceId
      title
    }
    }
  }
  allContentfulReportsTemplate {
    edges {
      node {
        title
        financial_reports_item {
          title
          presentation {
            title
          }
          audio {
            title
          }
          report {
            title
          }
          webcast {
            title
          }
        }
      }
    }
  }
  allContentfulHomeTemplate {
    edges {
      node {
        slug
        spaceId
        bottomExcerptText {
          childContentfulRichText {
            html
          }
        }
        bottomExcerptButtonUrl
        missionSection {
          childContentfulRichText {
            html
          }
        }
        topExcerptText {
          childContentfulRichText {
            html
          }
        }
        topExcerptButtonUrl
      }
    }
  }
  allContentfulAwardsTemplate {
    edges {
      node {
        slug
        spaceId
        bodyText {
          childContentfulRichText {
            html
          }
        }
        title
        childContentfulAwardsTemplateBodyTextRichTextNode {
          childContentfulRichText {
            html
          }
        }
      }
    }
  }
  allContentfulContactUsTemplate {
    edges {
      node {
        slug
        spaceId
        bodyText {
          childContentfulRichText {
            html
          }
        }
        title
      }
    }
  }
  allContentfulIframeTemplate {
    edges {
      node {
        slug
        spaceId
        iframeLink
        title
      }
    }
  }
  allContentfulCurrentVacanciesTemplate {
    edges {
      node {
        slug
        spaceId
        title
      }
    }
  }
  allContentfulAdvisersTemplate {
    edges {
      node {
        slug
        spaceId
        advisersItem {
          description
          title
        }
        title
      }
    }
  }
  allContentfulShareInformationTemplate {
    edges {
      node {
        slug
        spaceId
        title
        childContentfulShareInformationTemplateIframeTextRichTextNode {
          childContentfulRichText {
            html
          }
        }
        childContentfulShareInformationTemplateMarkdownTextNode {
          childMarkdownRemark {
            html
          }
        }
        iframeText {
          childContentfulRichText {
            html
          }
        }
        Markdown {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
  allContentfulAccordionTemplate {
    edges {
      node {
        slug
        spaceId
        title
        accordion {
          description
          title
        }
        bodyText {
          childContentfulRichText {
            html
          }
        }
        childContentfulAccordionTemplateBodyTextRichTextNode {
          childContentfulRichText {
            html
          }
        }
      }
    }
  } 
}
`


const SearchPage = ({location}) => {
  
    const globalSearchTerm = useSelector(state => state.ukHcpSearchTerm);
    const dispatch = useDispatch();

    const [results, setResults] = useState(null);
    const [queryParam, setQueryParam] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const query = useStaticQuery(ukHcpSearchQuery);

    useEffect(() => {
        const getSearchResults = async () => {
          let {q: searchWord} = queryString.parse(location.search || "")

          if(!isNullUndefined(searchWord) && searchWord.length > 0){
            // dispatch(ukHcpSearchTermOnchange(searchWord))
          }

            setQueryParam(searchWord)

            let results = await graphqlSearch(query, searchWord)
            console.log("Results", results)
            setResults(results)
        }
        getSearchResults();
    }, [])

    const submitSearch = async (term) => {
        setQueryParam(term);
        navigate(`/UkResidents/HCP/search?q=${term}`)
        let results = await graphqlSearch(query, term)
        setResults(results)
    }
    
    const searchOnchange = e => {
      if(!isNullUndefined(results) && isEmptyArray(results)){
        setResults(null)
      }
      dispatch(ukHcpSearchTermOnchange(e.target.value))
    }
    
    const searchKeyPress = e => {
        if(e.key === 'Enter'){
            submitSearch(globalSearchTerm)
        }
    }

    const handlePageClickPagination = ({ selected: selectedPage }) => {
      setCurrentPage(selectedPage);
    }

    const SEARCHED_ITEMS_TO_SHOW_PER_PAGE = 10;
    const ALREADY_DISPLAYED_ITEMS = currentPage * SEARCHED_ITEMS_TO_SHOW_PER_PAGE;
    const TOTAL_NUMBER_OF_PAGES = results ? Math.ceil(results.length / SEARCHED_ITEMS_TO_SHOW_PER_PAGE) : 0;

    return (
        <Layout>
        <SEO title={"Search"} />

            <div className="container">

            {(isNullUndefined(results) || isEmptyArray(results) || isNullUndefined(queryParam) || queryParam.length === 0) && (
                <div className="search_results" style={{marginTop:'150px'}}>
                    <input placeholder={"Search"} type="text" name="search" value={globalSearchTerm} onChange={searchOnchange} onKeyPress={searchKeyPress} />
                    <button onClick={() => submitSearch(globalSearchTerm)}>
                        Search
                    </button>
                    {(isEmptyArray(results) && !isNullUndefined(results) && !isNullUndefined(queryParam) && queryParam.length > 0) && <p>No results found for search term {globalSearchTerm}</p>}
                </div>
            )}

            {(!isNullUndefined(results) && !isEmptyArray(results) && !isNullUndefined(queryParam) && queryParam.length > 0) && (
                <React.Fragment>
                  <div className={searchStyles.search_list_wrap} style={{marginTop:'150px'}}> 
                    <h3>Your search {queryParam && ` for ${queryParam}`} matched {results.length} page(s).</h3>
                    <p>Showing results {ALREADY_DISPLAYED_ITEMS+1} to {(ALREADY_DISPLAYED_ITEMS+SEARCHED_ITEMS_TO_SHOW_PER_PAGE) > results.length ? ((results.length - ALREADY_DISPLAYED_ITEMS) + ALREADY_DISPLAYED_ITEMS) : ALREADY_DISPLAYED_ITEMS+SEARCHED_ITEMS_TO_SHOW_PER_PAGE}</p>
                        {results.slice(ALREADY_DISPLAYED_ITEMS, ALREADY_DISPLAYED_ITEMS + SEARCHED_ITEMS_TO_SHOW_PER_PAGE).map((item) => {
                            return (
                              <ScrollAnimation animateIn="fadeInUp" key={item.slug}>
                                <Link to={getSearchedPagePath(item.slug, query)}>
                                  <div className={searchStyles.search_list_item}>
                                    
                                    <div className="row">
                                          <div className="col-12 col-sm-12 col-md-12">
                                          <h4 className="mb-1">{item.title}</h4> 
                                          <ShowMore
                                            lines={2}
                                            more='Show more'
                                            less='Show less'
                                            anchorClass=''>
                                                <div
                                                  key={`body`}
                                                  id="___gatsby"
                                                  dangerouslySetInnerHTML={{ __html: `<p>${item.matchedSearchText}</p>` }}
                                                />  
                                          </ShowMore>
                                          </div>
                                      </div>
                                  </div>
                                </Link>
                              </ScrollAnimation>
                            )
                        })}
                  </div>
                  <ReactPaginate
                    previousLabel={"← Previous"}
                    nextLabel={"Next →"}
                    pageCount={TOTAL_NUMBER_OF_PAGES}
                    onPageChange={handlePageClickPagination}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />

                  {/* <input placeholder={"Search"} type="text" name="search" value={globalSearchTerm} onChange={searchOnchange} onKeyPress={searchKeyPress} />
                  <button onClick={() => submitSearch(globalSearchTerm)}>
                      Search
                  </button> */}
                </React.Fragment>
            )}

            </div>

        </Layout>
    )
}

export default SearchPage